body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.primary {
  color: green;
}

.App {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  grid-template-areas:
    "header header header"
    "toolbar toolbar toolbar"
    "left center right";
  grid-template-rows: 50px 50px auto;
  height: 100vh;
}

.App-loginButton, .App-message {
  display: block;
  margin: 100px auto;
  font-size: 50px;
}

.App-message {
  color: red;
  max-width: 60%;
}

.App-header {
  grid-area: header;
  background-color: #666666;
  color: #fafafa;
  text-align: center;
  font-size: 1.5em;
  height: 50px;
  line-height: 50px;
}

.App-header--production {
  background-color: #cb4154;
}

.App-logoutButton {
  float: right;
  margin: 16px 20px;
}

.App-toolbar {
  grid-area: toolbar;
  height: 50px;
}
.left-column {
  grid-area: left;
}
.center-column {
  grid-area: center;
}
.right-column {
  grid-area: right;
}
.left-column, .center-column, .right-column {
  overflow-y: scroll;
}

.Search {
  float: right;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  padding: 10px 20px;
}

.GameEmpty {
  border: solid 1px #ccc;
  border-radius: 5px;
  max-width: 500px;
  padding: 10px;
  margin: 10px;
}

.createGame {
  max-width: 500px;
}

.createGame fieldset {
  border: solid 1px #ccc;
  border-radius: 5px;
}

.createGame fieldset>p,
.createGame fieldset>label,
.createGame fieldset>input,
.createGame fieldset>textarea,
.createGame fieldset>button,
.detail-create-question-note {
  display: block;
  margin: 10px auto;
  width: 90%
}

.createGame fieldset>button {
  width: auto;
  margin: 10px;
}

.createGame label input, label select {
  margin-left: 10px;
}

.createGame .error-message {
  color: #cb4154;
}

.react-autosuggest__suggestion--highlighted {
  color: green;
  background-color: #ddd;
}

.GameViewContainer {
  max-width: 500px;
  padding: 10px;
  margin: 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.GameViewContainer p span {
  font-weight: bold;
}

.GameViewContainer .questions-unordered {
  list-style-type: disc;
  list-style-position: inside;
}

.GameViewContainer .questions-ordered {
  list-style-type: decimal;
  list-style-position: inside;
}

.GameViewEdit {
  float: right;
}

.GameViewContainer h3 {
  margin-top: 0;
}

a.AddGame {
  background-color: green;
  color: #fafafa;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 10px;
}

.Search {
  width: 70%;
  margin-top: 10px;
}

.Search-text {
  width: 70%;
  margin-right: 0.5em;
  padding-left: 0.5em;
  height: 26px;
}

.Search-text, .Search button {
  border: solid 1px #ccc;
  background-color: #fff;
  border-radius: 5px;
}

.Search button {
  height: 30px;
}

.TagItem-selected {
  font-weight: bold
}

.GameItem-edit-link {
  float: right;
}

.GameItem-name, .GameItem-question-preview {
  white-space: nowrap;
  overflow: hidden;
}

.GameItem-name {
  font-weight: bold;
}

.GameItem-question-preview {
  color: #666666;
}

.GameItem-display-info {
  font-size: 0.75em;
  white-space: nowrap;
  color: #666666;
}

.GameItem-tags {
  padding-left: 0.5em;
}

