.GameViewContainer {
  max-width: 500px;
  padding: 10px;
  margin: 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.GameViewContainer p span {
  font-weight: bold;
}

.GameViewContainer .questions-unordered {
  list-style-type: disc;
  list-style-position: inside;
}

.GameViewContainer .questions-ordered {
  list-style-type: decimal;
  list-style-position: inside;
}

.GameViewEdit {
  float: right;
}

.GameViewContainer h3 {
  margin-top: 0;
}
