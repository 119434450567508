.createGame {
  max-width: 500px;
}

.createGame fieldset {
  border: solid 1px #ccc;
  border-radius: 5px;
}

.createGame fieldset>p,
.createGame fieldset>label,
.createGame fieldset>input,
.createGame fieldset>textarea,
.createGame fieldset>button,
.detail-create-question-note {
  display: block;
  margin: 10px auto;
  width: 90%
}

.createGame fieldset>button {
  width: auto;
  margin: 10px;
}

.createGame label input, label select {
  margin-left: 10px;
}

.createGame .error-message {
  color: #cb4154;
}

.react-autosuggest__suggestion--highlighted {
  color: green;
  background-color: #ddd;
}
