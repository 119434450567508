.App {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  grid-template-areas:
    "header header header"
    "toolbar toolbar toolbar"
    "left center right";
  grid-template-rows: 50px 50px auto;
  height: 100vh;
}

.App-loginButton, .App-message {
  display: block;
  margin: 100px auto;
  font-size: 50px;
}

.App-message {
  color: red;
  max-width: 60%;
}

.App-header {
  grid-area: header;
  background-color: #666666;
  color: #fafafa;
  text-align: center;
  font-size: 1.5em;
  height: 50px;
  line-height: 50px;
}

.App-header--production {
  background-color: #cb4154;
}

.App-logoutButton {
  float: right;
  margin: 16px 20px;
}

.App-toolbar {
  grid-area: toolbar;
  height: 50px;
}
.left-column {
  grid-area: left;
}
.center-column {
  grid-area: center;
}
.right-column {
  grid-area: right;
}
.left-column, .center-column, .right-column {
  overflow-y: scroll;
}

.Search {
  float: right;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  padding: 10px 20px;
}
