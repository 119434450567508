.Search {
  width: 70%;
  margin-top: 10px;
}

.Search-text {
  width: 70%;
  margin-right: 0.5em;
  padding-left: 0.5em;
  height: 26px;
}

.Search-text, .Search button {
  border: solid 1px #ccc;
  background-color: #fff;
  border-radius: 5px;
}

.Search button {
  height: 30px;
}
