.GameItem-edit-link {
  float: right;
}

.GameItem-name, .GameItem-question-preview {
  white-space: nowrap;
  overflow: hidden;
}

.GameItem-name {
  font-weight: bold;
}

.GameItem-question-preview {
  color: #666666;
}

.GameItem-display-info {
  font-size: 0.75em;
  white-space: nowrap;
  color: #666666;
}

.GameItem-tags {
  padding-left: 0.5em;
}
